import styled from "styled-components";
import { Internal as InternalBase } from "@sablier/v2-components/atoms";
import { Button as ButtonBase } from "@sablier/v2-components/molecules";

export const Button = styled(ButtonBase)`
  height: ${(props) => props.theme.sizes.buttonToolbar};
  min-width: ${(props) => props.theme.sizes.buttonToolbar};
  min-height: ${(props) => props.theme.sizes.buttonToolbar};
  &,
  &[data-mini="true"] {
    *[data-component="title"] {
      p,
      span {
        font-weight: 600 !important;
        font-size: 12pt !important;
      }
    }
  }
`;

export const Internal = styled(InternalBase)`
  height: ${(props) => props.theme.sizes.buttonToolbar};
  &,
  &[data-mini="true"] {
    *[data-component="title"] {
      p,
      span {
        font-weight: 600 !important;
        font-size: 12pt !important;
      }
    }
  }
`;
