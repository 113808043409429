import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ArrowLeftIcon, InboxArrowDownIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import { pages } from "~/client/constants";
import { useAccount, useExpected, useModalExpected } from "~/client/hooks";
import { Button } from "../atoms";

const WrapperExpected = styled.div``;

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    &:not(:empty) {
      &:after {
        right: 0;
        content: "";
        width: 2px;
        height: 20px;
        margin-left: calc(${(props) => props.theme.sizes.edge} * 1 / 2 - 8px);
        background: ${(props) => props.theme.colors.dark400};
      }
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    & > div:not(${WrapperExpected}) {
      display: none;
    }
    ${WrapperExpected} {
      display: none;
    }
  }
`;

function Expected() {
  const { t } = useT();

  const { isConnected, isConfigured } = useAccount();
  const { doOpen: doOpenExpected } = useModalExpected();
  const { configured: list } = useExpected();

  const isExpected = useMemo(
    () => isConfigured && list.length !== 0,
    [isConfigured, list],
  );

  const openExpected = useCallback(() => {
    vendors.track.log((events) => events.openModal("expected"));
    doOpenExpected();
  }, [doOpenExpected]);

  if (!isConnected) {
    return false;
  }

  return (
    <WrapperExpected>
      {isExpected ? (
        <Button
          accent={"red"}
          appearance={"gradient"}
          isDisabled={!isConfigured}
          isMini
          left={InboxArrowDownIcon}
          onClick={openExpected}
          purpose={"button"}
          tooltip={{
            color: "dark250",
            value: t("structs.expectedStreams"),
          }}
        />
      ) : (
        <Button
          accent={"toolbar"}
          appearance={"transparent"}
          isDisabled={!isConfigured}
          isMini
          left={InboxArrowDownIcon}
          onClick={openExpected}
          purpose={"button"}
          tooltip={{
            color: "dark250",
            value: t("structs.expectedStreams"),
          }}
        />
      )}
    </WrapperExpected>
  );
}

function Navigation() {
  const router = useRouter();

  const { t } = useT();

  const Specific = useCallback(() => {
    const back = (() => {
      switch (router.pathname) {
        case pages.vesting.create.route:
        case pages.vesting.createCSV.route:
          return pages.vesting.gallery.builder();
        case pages.vesting.gallery.route:
          return pages.vesting.dashboard.builder();
        case pages.payments.create.route:
        case pages.payments.createCSV.route:
          return pages.payments.dashboard.builder();
        case pages.drops.create.route:
        case pages.drops.profile.route:
          return pages.drops.dashboard.builder();
        default:
          return undefined;
      }
    })();

    if (!_.isNilOrEmptyString(back)) {
      return (
        <Button
          accent={"toolbar"}
          appearance={"transparent"}
          isMini
          left={ArrowLeftIcon}
          title={""}
          purpose={"internal"}
          to={back}
          tooltip={{
            color: "dark250",
            value: t("structs.goBack"),
            mouseEnterDelay: 0,
            mouseLeaveDelay: 0,
          }}
        />
      );
    }
  }, [router, t]);

  return (
    <Wrapper>
      <Specific />
      <Expected />
    </Wrapper>
  );
}

export default Navigation;
