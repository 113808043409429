import { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "@sablier/v2-assets/logo/long-gradient.svg";
import { Internal } from "@sablier/v2-components/atoms";
import { routes } from "@sablier/v2-constants";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import Account from "./Account";
import Chain from "./Chain";
import Navigation from "./Navigation";
import Preflight from "./Preflight";
import Routes from "./Routes";

const WrapperPartial = styled.nav`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexToolbar};
  width: 100%;
  height: ${(props) => props.theme.sizes.toolbar};
  border-bottom: 2px solid ${(props) => props.theme.colors.border};

  &[data-home="true"] {
    border-bottom: 2px solid ${(props) => props.theme.colors.dark250};
  }
`;

const Underlay = styled.div<{ z?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  ${(props) => props.theme.styles.row}

  & {
    justify-content: space-between;
    height: 100%;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }
`;

const Branding = styled(Internal)`
  width: 130px;
  height: 100%;
  margin: 0 auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    height: 100%;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: 100%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Branding} {
      flex-shrink: 0;
      width: 140px;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Branding} {
      flex-shrink: 0;
      width: 120px;
    }
  }
`;

function Toolbar() {
  const router = useRouter();

  const isHome = useMemo(
    () => router.pathname === routes.client.pages.dashboard.route,
    [router],
  );

  return (
    <Wrapper data-home={isHome}>
      <Underlay>
        <Container>
          <Branding
            onClick={() => {
              vendors.track.log("clickLogo");
            }}
            to={routes.client.pages.dashboard.builder()}
          >
            <Logo />
          </Branding>
        </Container>
      </Underlay>
      <Main>
        <Container>
          <Left>
            <Routes />
          </Left>
          <Right>
            <Navigation />
            <Chain />
            <Account />
            <Preflight />
          </Right>
        </Container>
      </Main>
    </Wrapper>
  );
}

export default Toolbar;
