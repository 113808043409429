import { useCallback, useEffect, useState } from "react";
import { _ } from "@sablier/v2-mixins";
import type { IMilliseconds } from "@sablier/v2-types";

/**
 * @param frequency
 * @param start
 * @param difference
 */

interface Props {
  /** Difference to add on top of `start`. Will default to `0` */
  difference: IMilliseconds | number;
  /** Frequency of the updates. */
  frequency?: number;
}

function compute(difference = 0) {
  const start = Date.now();
  return start + difference;
}

export default function usePolledDate({
  difference,
  frequency = 20 * 1000,
}: Props) {
  const [end, setEnd] = useState(compute(_.toNumber(difference)));

  const update = useCallback(() => {
    setEnd(compute(_.toNumber(difference)));
  }, [difference, setEnd]);

  useEffect(() => {
    update();
    const interval = setInterval(() => {
      update();
    }, frequency);

    return () => clearInterval(interval);
  }, [update, frequency]);

  return end;
}
