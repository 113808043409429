import { useCallback, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import {
  ArrowLeftIcon,
  EllipsisHorizontalIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import AssetCoinbase from "@sablier/v2-assets/wallet/coinbase.png";
import AssetSafe from "@sablier/v2-assets/wallet/safe.svg";
import AssetWalletConnect from "@sablier/v2-assets/wallet/walletConnect.png";
import { tags } from "@sablier/v2-constants";
import { useShortAddressOrName } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useAccount, useModalAccount } from "~/client/hooks";
import { Button } from "../atoms";

const Wrapper = styled.div`
  ${(props) => props.theme.medias.maxXL} {
    & > *[data-component="button"] {
      *[data-component="side"]:last-child {
        display: none;
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    & > *[data-component="button"] {
      *[data-component="title"],
      *[data-component="side"]:last-child {
        display: none;
      }
    }
  }
`;

const Pulse = keyframes`
  0%{
    opacity: 0;
    transform: scale(0.5)
  }

  40%{
    opacity: 0.4;
    transform: scale(1)
  }

  50%{
    opacity: 0;
    transform: scale(3)
  }
`;

const Call = styled.div`
  & > *[data-component="button"] {
    position: relative;
    overflow: hidden;
    &:before {
      position: absolute;
      left: -20px;
      content: "";
      z-index: 40;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: ${(props) => props.theme.gradients.thumbnailWhite};
      opacity: 0;
      filter: blur(0);
      pointer-events: none;
      animation-name: ${Pulse};
      animation-duration: 5s;
      animation-timing-function: ease-out;
      animation-delay: 3s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }
    &:after {
      position: absolute;
      left: -60px;
      content: "";
      z-index: 40;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: ${(props) => props.theme.gradients.thumbnailWhite};
      opacity: 0;
      filter: blur(0);
      pointer-events: none;
      animation-name: ${Pulse};
      animation-duration: 5s;
      animation-timing-function: ease-out;
      animation-delay: 3s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }

    &:hover,
    &:active {
      &:before,
      &:after {
        visibility: hidden;
      }
    }
  }
`;

function AccountConnected() {
  const { address, connector, ens, isHostSafe } = useAccount();
  const shortAccount = useShortAddressOrName(ens || address, 8);

  const { setOpen } = useModalAccount();

  const openModal = useCallback(
    (val: boolean) => {
      vendors.track.log((events) => {
        return events.openModal("account");
      });
      setOpen(val);
    },
    [setOpen],
  );

  const left = useMemo(() => {
    const o = { leftPurpose: "image" as const } as const;

    if (isHostSafe) {
      return { ...o, left: AssetSafe };
    }

    if (connector?.id === "coinbaseWallet") {
      return { ...o, left: AssetCoinbase };
    }

    if (
      connector?.id === "walletConnect" ||
      connector?.id === "walletConnectLegacy"
    ) {
      return { ...o, left: AssetWalletConnect };
    }

    return {
      left: address,
      leftPurpose: "identicon" as const,
    };
  }, [address, connector, isHostSafe]);

  return (
    <Button
      appearance={"transparent"}
      accent={"toolbar"}
      isMini
      {...left}
      cy={tags.auth}
      onClick={() => openModal(true)}
      right={EllipsisHorizontalIcon}
      title={shortAccount}
    />
  );
}

function AccountDisconnected() {
  const { connect } = useAccount();
  const { t } = useT();

  const connectAction = useCallback(() => {
    vendors.track.log("clickOnConnect");
    if (connect) {
      connect();
    }
  }, [connect]);

  return (
    <Call>
      <Button
        accent={"dark300"}
        appearance={"solid"}
        isMini
        onClick={connectAction}
        left={UserCircleIcon}
        title={t("structs.connectNow")}
      />
    </Call>
  );
}

function AccountConnecting() {
  const { disconnect } = useAccount();
  const { t } = useT();

  return (
    <Button
      appearance={"transparent"}
      accent={"toolbar"}
      isMini
      forceLoadingSide={"left"}
      leftPurpose={"empty"}
      left={"dark500"}
      isPreview
      onClick={disconnect}
      right={EllipsisHorizontalIcon}
      title={t("words.connecting")}
    />
  );
}

function AccountUnsure() {
  const { disconnect } = useAccount();
  const { t } = useT();

  return (
    <Button
      appearance={"transparent"}
      accent={"toolbar"}
      isMini
      left={ArrowLeftIcon}
      isPreview
      onClick={disconnect}
      title={t("structs.almostThere")}
    />
  );
}

function Account() {
  const { isMounted, status } = useAccount();

  return (
    <Wrapper>
      {isMounted && (
        <>
          {status === "unsure" && <AccountUnsure />}
          {status === "connected" && <AccountConnected />}
          {status === "disconnected" && <AccountDisconnected />}
          {(status === "reconnecting" || status === "connecting") && (
            <AccountConnecting />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Account;
