import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useAccount, useChain, useModalChainSwitcher } from "~/client/hooks";
import type { ComponentProps } from "react";
import { Button } from "../atoms";

const Wrapper = styled.div`
  ${(props) => props.theme.medias.maxMD} {
    & > *[data-component="button"] {
      *[data-component="title"],
      *[data-component="side"]:last-child {
        display: none;
      }
    }
  }
`;

function Chain() {
  const { t } = useT();
  const { isMounted, isSupported } = useAccount();
  const { chain } = useChain();
  const { category, doOpen } = useModalChainSwitcher();
  const image = useMemo(() => chain?.image, [chain]);
  const name = useMemo(
    () =>
      _.truncate(chain?.name ?? "Wrong chain", {
        length: 15,
        omission: "..",
      }),
    [chain],
  );

  const [accent, appearance] = useMemo(
    () =>
      chain && isSupported
        ? (["toolbar", "transparent"] as const)
        : (["warningLight", "solid"] as const),
    [chain, isSupported],
  );

  const left = useMemo((): Partial<ComponentProps<typeof Button>> => {
    if (image) {
      return {
        leftPurpose: "image",
        left: image,
      };
    }
    return {
      leftPurpose: "empty",
    };
  }, [image]);

  const onClick = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModal("chainPicker");
    });
    doOpen();
  }, [doOpen]);

  if (!isMounted) {
    return false;
  }

  if (category === "unsupported") {
    return (
      <Wrapper>
        <Button
          accent={accent}
          appearance={appearance}
          isMini
          {...left}
          title={name}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Button
        accent={accent}
        appearance={appearance}
        cy={tags.chainPicker}
        isMini
        {...left}
        onClick={onClick}
        right={ChevronDownIcon}
        title={name}
        tooltip={{
          color: "dark250",
          value: _.capitalize(t("structs.switchChain")),
        }}
      />
    </Wrapper>
  );
}

export default Chain;
