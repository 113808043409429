import styled from "styled-components";
import {
  ArrowUturnRightIcon,
  CubeIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { Title as TitleBase } from "@sablier/v2-components/atoms";
import { Icon } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { useRouter } from "next/router";
import { pages } from "~/client/constants";
import { Internal } from "../atoms";

/**
 * The negative margin aligns the toolbar content
 * closer to the left edge of the page container.
 */
const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: 100%;
    margin-left: -11px;
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: 0px;
  }
`;

const Element = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    gap: 5px;
    height: ${(props) => props.theme.sizes.buttonToolbar};
    padding-right: 4px;
    padding-left: 8px;
    border-radius: 6px;

    &[data-purpose="${pages.payments.dashboard.route}"] {
      margin-left: 1px;
    }
  }
`;

const Title = styled(TitleBase)`
  &,
  &[data-mini="true"] {
    & > p {
      ${(props) => props.theme.styles.textGradient}
      & {
        color: ${(props) => props.theme.colors.gray300};
        font-weight: 600 !important;
        background-image: ${(props) => props.theme.gradients.textGray};
      }
    }
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    width: 18px;
    height: 18px;

    &:after {
      position: absolute;
      top: -3px;
      left: -3px;
      content: "";
      z-index: 0;
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    & > * {
      z-index: 5;
    }

    svg {
      stroke: ${(props) => props.theme.gradients.svgUrl.textGray};
    }
  }
`;

const Active = styled(Element)`
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors.dark050} 30%,
    ${(props) => props.theme.colors.dark050} 100%
  );

  ${Title} {
    p {
      color: ${(props) => props.theme.colors.white};
      background-image: ${(props) => props.theme.gradients.white};
    }
  }

  ${Box} {
    &:after {
      background: ${(props) => props.theme.gradients.primaryDark};
    }
    svg {
      stroke: ${(props) => props.theme.colors.white};
    }
  }

  &[data-purpose="${pages.drops.dashboard.route}"] {
    ${Box} {
      &:after {
        background: ${(props) => props.theme.gradients.purple};
      }
    }
  }
`;

const Inactive = styled(Element)`
  cursor: pointer;

  &:hover {
    ${Title} {
      p {
        color: ${(props) => props.theme.colors.white};
        background-image: ${(props) => props.theme.gradients.white};
      }
    }
    ${Box} {
      svg {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const icons = {
  [pages.dashboard.route]: Squares2X2Icon,
  [pages.vesting.dashboard.route]: ShieldCheckIcon,
  [pages.payments.dashboard.route]: ArrowUturnRightIcon,
  [pages.drops.dashboard.route]: CubeIcon,
} as const;

export default function Routes() {
  const { t } = useT();
  const router = useRouter();

  return (
    <Wrapper>
      <List>
        {[
          pages.dashboard,
          pages.vesting.dashboard,
          pages.payments.dashboard,
          pages.drops.dashboard,
        ].map((page) => {
          const isActive =
            router.pathname === page.route ||
            (page.route !== "/" && router.pathname.startsWith(page.route));

          const Element = isActive ? Active : Inactive;

          return (
            <Element
              data-purpose={page.route}
              key={page.route}
              to={page.builder()}
            >
              <Box>
                <Icon value={icons[page.route]} />
              </Box>
              <Title isMini title={t(page.locales)} />
            </Element>
          );
        })}
      </List>
    </Wrapper>
  );
}
