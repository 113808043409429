import { useMemo } from "react";
import styled from "styled-components";
import { CodeBracketSquareIcon } from "@heroicons/react/24/outline";
import {
  Amount,
  Summary as Base,
  Button,
  Chain,
  Token,
} from "@sablier/v2-components/molecules";
import { usePolledDate } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps } from "react";
import type { ISummaryExtension, ISummaryItem } from "~/client/types";

interface Props {
  isInitiallyOpen?: boolean;
  extension?: ISummaryExtension;
  isComplete: boolean;
  isValid: boolean;
  list: ISummaryItem[];
  title: string;
  total: ComponentProps<typeof Base.Card>["total"];
}

const Extended = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    &:not(:empty) {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      width: 100%;
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      border-top: 2px solid ${(props) => props.theme.colors.dark500};
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.dark500};
`;

const Scroller = styled.div`
  ${(props) => props.theme.styles.hiddenScrollbar}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    width: 100%;
    max-height: 130px;
    padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    padding-left: calc(${(props) => props.theme.sizes.edge} * 1);
    border-left: 2px solid ${(props) => props.theme.colors.dark500};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.dark200};
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const Placeholder = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      color: ${(props) => props.theme.colors.dark1000};
    }
  }
`;

function ElementsDuration({
  duration,
  isRangeShown = true,
}: {
  duration?: string;
  isRangeShown?: boolean;
}) {
  const { t } = useT();
  const milliseconds = useMemo(
    () => (duration ? _.toNumber(duration) : 0),
    [duration],
  );

  const start = usePolledDate({ difference: 60 * 1000 });
  const end = usePolledDate({ difference: milliseconds });

  const display = useMemo(() => {
    if (_.isNilOrEmptyString(duration)) {
      return [undefined, undefined, undefined];
    }

    return [
      _.toDuration(duration, "time")[0],
      _.toDuration(start, "date-estimate")[0],
      _.toDuration(end, "date-estimate")[0],
      _.toDuration(start, "date-estimate", true)[0],
      _.toDuration(end, "date-estimate", true)[0],
    ];
  }, [duration, end, start]);

  return (
    <>
      <Base.Detail
        title={`${_.capitalize(t("words.for"))}:`}
        value={display[0]}
      />
      {isRangeShown ? (
        <>
          <Base.Detail
            title={`${t("structs.startOn")}:`}
            value={display[1]}
            tooltip={{ arrow: true, value: `${display[3]} UTC` }}
          />
          <Base.Detail
            title={`${t("structs.endOn")}:`}
            value={display[2]}
            tooltip={{ arrow: true, value: `${display[4]} UTC` }}
          />
        </>
      ) : (
        false
      )}
    </>
  );
}

function ElementsRange({ end, start }: { end?: string; start?: string }) {
  const { t } = useT();
  const difference = useMemo(() => {
    return Math.abs(_.toNumber(end || 0) - _.toNumber(start || 0));
  }, [end, start]);

  const display = useMemo(() => {
    const e = !_.isNilOrEmptyString(end)
      ? _.toDuration(end || 0, "date")[0]
      : undefined;
    const s = !_.isNilOrEmptyString(start)
      ? _.toDuration(start || 0, "date")[0]
      : undefined;
    const d =
      !_.isNilOrEmptyString(difference) && e && s
        ? _.toDuration(difference, "time")[0]
        : undefined;

    const te = !_.isNilOrEmptyString(end)
      ? _.toDuration(end || 0, "date", true)[0]
      : undefined;

    const ts = !_.isNilOrEmptyString(start)
      ? _.toDuration(start || 0, "date", true)[0]
      : undefined;

    return [d, s, e, ts, te];
  }, [end, difference, start]);

  return (
    <>
      <Base.Detail
        title={`${t("structs.forAround")}:`}
        value={display[0] ?? "invalid"}
      />
      <Base.Detail
        title={`${_.capitalize(t("words.start"))}:`}
        value={display[1] ?? "invalid"}
        tooltip={{ arrow: true, value: `${display[3]} UTC` }}
      />
      <Base.Detail
        title={`${_.capitalize(t("words.end"))}:`}
        value={display[2] ?? "invalid"}
        tooltip={{ arrow: true, value: `${display[4]} UTC` }}
      />
    </>
  );
}

function ElementsRangeInvalid() {
  const { t } = useT();
  return (
    <>
      <Invalid title={`${t("structs.forAround")}:`} />
      <Invalid title={`${_.capitalize(t("words.start"))}:`} />
      <Invalid title={`${_.capitalize(t("words.end"))}:`} />
    </>
  );
}

function ElementsDurationInvalid() {
  const { t } = useT();
  return (
    <>
      <Invalid title={`${_.capitalize(t("words.for"))}:`} />
      <Invalid title={`${t("structs.startOn")}:`} />
      <Invalid title={`${t("structs.endOn")}:`} />
    </>
  );
}

function Invalid({ title }: { title: string }) {
  const { t } = useT();
  return (
    <Base.Detail
      title={title}
      value={_.capitalize(t("words.invalid"))}
      isInvalid={true}
    />
  );
}

export function Value({ item }: { item: ISummaryItem }) {
  switch (item.purpose) {
    case "action":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Base.Detail title={item.label}>
            <Button
              appearance={"transparent"}
              accent={"gray400"}
              isMini
              isUnpadded
              left={CodeBracketSquareIcon}
              onClick={item.value.onClick}
              title={item.value.title}
            />
          </Base.Detail>
        );
      }

      return false;
    case "amount":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Base.Detail title={item.label}>
            <Amount token={item.token} size={18} value={item.value} />
          </Base.Detail>
        );
      }
      return <Invalid title={item.label} />;
    case "chain":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Base.Detail title={item.label}>
            <Chain {...item.value} />
          </Base.Detail>
        );
      }
      return <Invalid title={item.label} />;
    case "date":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Base.Detail
            title={item.label}
            value={_.toDuration(item.value || 0, "date")[0]}
            tooltip={{
              arrow: true,
              value: `${_.toDuration(item.value || 0, "date", true)[0]} UTC`,
            }}
          />
        );
      }
      return <Invalid title={item.label} />;
    case "text":
      if (!_.isNilOrEmptyString(item.value)) {
        return <Base.Detail title={item.label} value={item.value} />;
      }
      return <Invalid title={item.label} />;
    case "divider":
      return <Divider />;
    case "duration":
      if (!_.isNilOrEmptyString(item.duration)) {
        return (
          <ElementsDuration
            duration={item.duration}
            isRangeShown={item.isRangeShown}
          />
        );
      }
      return <ElementsDurationInvalid />;
    case "range":
      if (
        !_.isNilOrEmptyString(item.start) &&
        !_.isNilOrEmptyString(item.end)
      ) {
        return <ElementsRange end={item.end} start={item.start} />;
      }
      return <ElementsRangeInvalid />;
    case "token":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Base.Detail title={item.label}>
            <Token {...item.value} />
          </Base.Detail>
        );
      }
      return <Invalid title={item.label} />;

    case "scroller":
      if (!_.isNilOrEmptyString(item.value)) {
        return (
          <Scroller>
            {item.value.map((row, index) => (
              <Base.Detail
                key={row}
                title={`${item.label} ${index + 1}: `}
                value={row}
              />
            ))}
          </Scroller>
        );
      }
      return <Invalid title={item.label} />;
    default:
      return false;
  }
}

function Item({
  extension,
  isComplete,
  isInitiallyOpen = false,
  isValid,
  list,
  title,
  total,
}: Props) {
  const { t } = useT();
  const isCompleteAndValid = useMemo(() => {
    const base = isComplete && isValid;
    if (!_.isNil(extension)) {
      return base && extension.isComplete && extension.isValid;
    }
    return base;
  }, [extension, isComplete, isValid]);

  return (
    <Base.Card
      isComplete={isCompleteAndValid}
      isInitiallyOpen={isInitiallyOpen}
      title={title}
      total={total}
    >
      {!isComplete ? (
        <Placeholder>
          <p>{t("structs.fillForSummary")}</p>
        </Placeholder>
      ) : (
        <>
          {list.map((item, index) => (
            <Value key={index} item={item} />
          ))}
          {!_.isNil(extension) ? (
            <Extended>
              {!extension.isComplete ? (
                <Placeholder>
                  <p>{t("structs.fillExtra")}</p>
                </Placeholder>
              ) : (
                extension.list.map((item, index) => (
                  <Value key={index} item={item} />
                ))
              )}
            </Extended>
          ) : undefined}
        </>
      )}
    </Base.Card>
  );
}

export default Item;
